<template>
  <div class="d-flex flex-column justify-center align-center --full-height">
    <v-progress-circular
      indeterminate
      color="primary"
      size="64"
    ></v-progress-circular>
    <span
      class="mt-8"
      :class="{ 'red--text': errMsg, 'green--text': !errMsg }"
      >{{ msg }}</span
    >
    <span :class="{ 'red--text': errMsg, 'green--text': !errMsg }">{{
      errMsg
    }}</span>
    <span>Please wait...</span>
  </div>
</template>

<script>
export default {
  name: "Callback",
  data: () => ({
    refresh: null,
    msg: "Authentication check...",
    errMsg: null,
  }),
  mounted() {
    this.refresh = this.$route.query.token;
    if (this.refresh) {
      this.handleCallback(this.refresh);
    } else {
      this.msg = "Authentication fail.";
      this.errMsg = "Error: Token not found.";
      setTimeout(() => {
        this.$router.replace("/");
      }, 480);
    }
  },
  methods: {
    async handleCallback(refresh) {
      localStorage.setItem("refresh", refresh);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ refresh: refresh }),
      };
      const result = await fetch(
        `${process.env.VUE_APP_DOMAIN}/api/auth/token/refresh/`,
        requestOptions
      );
      const data = await result.json();
      if (result.status === 200) {
        this.msg = "Authentication successful.";
        console.log("pass callback success");
        localStorage.setItem("token", data.access);
        this.$store.commit("SET_LOGIN");
        setTimeout(() => {
          this.$router.replace("/");
        }, 480);
      } else {
        this.msg = "Authentication fail.";
        this.errMsg = "Error: " + data.detail;
        setTimeout(() => {
          this.$router.replace("/");
        }, 480);
      }
    },
  },
};
</script>

<style>
.--full-height {
  height: 100%;
}
</style>
